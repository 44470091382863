import React from "react";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import Texts from "../../resources/Texts";
import { LicencesTable } from "../../components/licencesTable/LicencesTable";

const StyledViewContainer = styled(Box)({
	paddingTop: "24px",
	display: "flex",
	flexDirection: "column",
	" .description": {
		marginTop: "12px",
		maxWidth: "774px",
	},
	height: "100%",
});

export const LicencesView: React.FC = () => {
	return (
		<StyledViewContainer display="flex" flexDirection="column">
			<Typography variant="h1">{Texts.Views.Licences.Title}</Typography>

			<Typography variant="body1" className="description">
				{Texts.Views.Licences.Description}
			</Typography>

			<LicencesTable />
		</StyledViewContainer>
	);
};
