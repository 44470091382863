import React from "react";
import logo from "./Logo.svg";
import { styled } from "@mui/system";

const StyledHeader = styled("header")({
	backgroundColor: "#ffffff",
	height: "64px",
	boxSizing: "border-box",
	padding: "8px 16px",
	display: "flex",
	alignItems: "center",
	borderBottom: "1px solid #e4e4e4",
});

export const Header: React.FC = () => {
	return (
		<StyledHeader>
			<a href="https://www.skoda-auto.com/" target="_blank">
				<img src={logo} alt="logo" width="120px" height="48px" />
			</a>
		</StyledHeader>
	);
};
