import { Box, Collapse, TableCell, TableRow, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { ProgressBar } from "@skodaflow/web-library";
import React from "react";
import { useParams } from "react-router-dom";
import { ILicenseListItem } from "../../resources/Contracts";
import { Service } from "../../service/Service";

const StyledTableCell = styled(TableCell)({
	"&.MuiTableCell-root": {
		borderBottomColor: "#E4E4E4",
	},
});

type Props = {
	licenceItem: ILicenseListItem;
};

export const LicenceRow: React.FC<Props> = ({ licenceItem }) => {
	const { projectName } = useParams();

	const [open, setOpen] = React.useState(false);
	const [content, setContent] = React.useState<string>();
	const [loading, setLoading] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (content) {
			setOpen(true);
		}
	}, [content]);

	const onOpenedChange = React.useCallback(async () => {
		if (!content) {
			const timeout = setTimeout(() => setLoading(true), 200);

			try {
				const result = await Service.getLicenceFile(
					projectName,
					licenceItem.type,
					licenceItem.name,
					licenceItem.version
				);

				setContent(result);
			} catch (error) {
				console.error(error);
			} finally {
				clearTimeout(timeout);
				setLoading(false);
			}
		} else {
			setOpen((o) => !o);
		}
	}, [licenceItem, content]);

	const title: string = React.useMemo(
		() =>
			`${licenceItem.name || ""}${
				licenceItem.version ? ", " + licenceItem.version : ""
			}${licenceItem.license ? ", " + licenceItem.license : ""}`,
		[licenceItem]
	);

	return (
		<>
			<TableRow
				sx={{
					cursor: "pointer",
				}}
				onClick={onOpenedChange}
			>
				<StyledTableCell
					scope="row"
					sx={{
						border: "none",
						padding: "25px 0",
					}}
				>
					<Box display="flex" alignItems="center">
						<Typography
							variant="subtitle2"
							display="inline"
							sx={{
								wordBreak: "break-word",
							}}
						>
							{title}
						</Typography>

						{loading && (
							<ProgressBar
								variant="spinner"
								style={{
									display: "inline",
									width: "unset",
								}}
							/>
						)}
					</Box>
				</StyledTableCell>
				<StyledTableCell
					width="55px"
					sx={{
						border: "none",
					}}
				>
					<Box
						sx={{
							transition: "200ms ease-in-out",
							transform: open && "rotate(45deg)",
							width: "24px",
							height: "24px",
							fontSize: "30px",
							fontWeight: 400,
						}}
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<span>+</span>
					</Box>
				</StyledTableCell>
			</TableRow>
			<TableRow>
				<StyledTableCell colSpan={6} style={{ padding: 0 }}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Typography
							variant="body1"
							sx={{ padding: "0 0 25px", whiteSpace: "pre-line" }}
						>
							{licenceItem.copyright && (
								<Box component="span" display="block">
									{licenceItem.copyright}
								</Box>
							)}
							{content}
						</Typography>
					</Collapse>
				</StyledTableCell>
			</TableRow>
		</>
	);
};
