import { styled } from "@mui/system";
import React from "react";
import Texts from "../../../resources/Texts";
import { Container, Typography } from "@skodaflow/web-library";

const StyledFooter = styled("footer")({
	height: "132px",
	backgroundColor: "#0E3A2F",
	color: "#C4C6C7",
	display: "flex",
	alignItems: "center",
	boxSizing: "border-box",
});

export const Footer: React.FC = () => {
	return (
		<StyledFooter>
			<Container fixed>
				<Typography variant="body1">{Texts.FooterCopyright}</Typography>
			</Container>
		</StyledFooter>
	);
};
