import axios, { AxiosResponse, Method } from "axios";
import { ILicenseListItem } from "../resources/Contracts";

export class Service {
	private static instance = axios.create();

	protected static async callApi(
		apiUrl: string,
		method: Method,
		payload?: any
	) {
		let request: AxiosResponse<any>;

		switch (method) {
			case "GET":
				request = await Service.instance.get(apiUrl);
				break;

			case "POST":
				request = await Service.instance.post(apiUrl, payload);
				break;

			case "PUT":
				request = await Service.instance.put(apiUrl, payload);
				break;

			case "DELETE":
				request = await Service.instance.delete(apiUrl);
				break;
		}

		return request.data;
	}

	public static async getLicencesList(
		projectName: string,
		queryParams: URLSearchParams
	): Promise<ILicenseListItem[]> {
		const requestUrl: string = `api/license/${projectName}?${queryParams.toString()}`;

		return Service.callApi(requestUrl, "GET");
	}

	public static async getLicenceFile(
		projectName: string,
		type: string,
		licenseName: string,
		version: string
	): Promise<string> {
		const urlParams: URLSearchParams = new URLSearchParams();

		urlParams.set("type", type);
		urlParams.set("name", licenseName);
		urlParams.set("version", version);

		const requestUrl: string = `api/license/file/${projectName}?${urlParams.toString()}`;

		return Service.callApi(requestUrl, "GET");
	}
}
