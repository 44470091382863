const Texts = {
	Views: {
		Licences: {
			Title: "Free and open-source software",
			Description:
				"Free and open-source software (FOSS) is a term used to refer to groups of software consisting of both free software and open-source software where anyone is freely licensed to use, copy, study, and change the software in any way, and the source code is openly shared so that people are encouraged to voluntarily improve the design of the software.",
		},
	},
	FooterCopyright: "Copyright ©2023 Škoda Auto a.s.",
};

export default Texts;
