import React from "react";
import { render } from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@skodaflow/web-library/theme/skoda/globals.css";
import "@skodaflow/web-library/theme/skoda/fonts.css";
import "@skodaflow/web-library/theme/skoda/icons.css";


const root = document.getElementById("root");
render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	root
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
