import { styled } from "@mui/system";
import { Container } from "@skodaflow/web-library";
import React from "react";
import { Footer } from "./footer/Footer";
import { Header } from "./header/Header";
import { Outlet } from "react-router-dom";

const StyledLayout = styled("div")({
	height: "100%",
	" .foss-layoutContainer": {
		height: "calc(100% - 64px - 132px)",
		overflowY: "auto",
	},
});

export const Layout: React.FC = () => {
	return (
		<StyledLayout className="App">
			<Header />

			<Container className="foss-layoutContainer" fixed>
				<Outlet />
			</Container>

			<Footer />
		</StyledLayout>
	);
};
