import { Box, Table, TableBody, TableContainer } from "@mui/material";
import { ProgressBar } from "@skodaflow/web-library";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ILicenseListItem } from "../../resources/Contracts";
import { Service } from "../../service/Service";
import { LicenceRow } from "./LicenceRow";

export const LicencesTable: React.FC = () => {
	const { projectName } = useParams();
	const [searchParams] = useSearchParams();

	const [licences, setLicences] = React.useState<ILicenseListItem[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);

	React.useEffect(() => {
		getLicenceList();
	}, []);

	const getLicenceList = React.useCallback(async () => {
		setLoading(true);

		try {
			const result: ILicenseListItem[] = await Service.getLicencesList(
				projectName,
				searchParams
			);

			setLicences(result);
		} catch (error) {
			console.error(error);
		}

		setLoading(false);
	}, []);

	return (
		<Box sx={{ mt: "50px" }} display="flex" flex={1}>
			{loading ? (
				<ProgressBar
					variant="spinner"
					style={{
						justifyContent: "center",
					}}
				/>
			) : (
				<TableContainer
					sx={{
						boxShadow: "none",
						borderTop: "1px solid #E4E4E4",
					}}
				>
					<Table aria-label="collapsible table">
						<TableBody>
							{licences.map((row, index) => (
								<LicenceRow
									key={`${row.name}-${index}`}
									licenceItem={row}
								/>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</Box>
	);
};
