import { styled } from "@mui/system";
import { SkodaTheme } from "@skodaflow/web-library";
import { RouterProvider } from "react-router-dom";
import { useRouter } from "./Routes";

const CustomBaseline = styled("div")({
	height: "100%",
	" .MuiScopedCssBaseline-root": {
		height: "100%",
	},
});

function App() {
	const router = useRouter();

	return (
		<CustomBaseline>
			<SkodaTheme>
				<RouterProvider router={router} />
			</SkodaTheme>
		</CustomBaseline>
	);
}

export default App;
