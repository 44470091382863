import { createBrowserRouter } from "react-router-dom";
import { Layout } from "./components/layout/Layout";
import { LicencesView } from "./views/licences/LicencesView";

export const routes = {
	home: "/",
};

export function useRouter() {
	return createBrowserRouter([
		{
			element: <Layout />,
			errorElement: <>error</>,
			path: routes.home,
			children: [
				{
					path: ":projectName",
					element: <LicencesView />,
				},
			],
		},
	]);
}
